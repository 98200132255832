<template>
  <div class="gamesecurity">
    <Head />
    <TitleBg :flag="showTitle" />
    <div class="main">
      <div class="doing-what">游戏安全处罚规则</div>
      <div class="help-content">
        <div class="help-box">
          <ul>
            <li>
              <div class="bottom problem">
                <div class="p_content">
                  <p class="p_title">游戏安全处罚规则</p>
                  <p>
                    欢迎您使用和接受本公司（包括但不限于其相关工作室，以下统称“公司”或“我们”）提供的游戏产品服务。公司特别提醒用户认真阅读本协议的全部条款，特别是其中免除或者限制本公司责任的条款（该等条款通常含有“不负任何责任”、“无义务”、“不保证”等词汇）、限制用户权利的条款（该等条款通常含有“不得”、“不应”、“无权”等词汇）。</p>
                  <p class="p_title1"><strong>第一条&nbsp;&nbsp;游戏软件使用许可与限制 </strong></p>
                  <p>
                    1、在用户同意接受本协议全部条款的前提下，本公司同意授予用户非商业使用性质的、可撤销的、可变更的、非专有的、不可转让和不可转授权地使用本公司游戏软件及本公司游戏服务的权利。用户可在授权范围内将本公司游戏软件安装在供个人使用的移动智能设备上，并以本公司指定的方式运行本公司游戏软件的一份副本并享受本公司提供的服务。 </p>
                  <p>
                    本公司基于本协议授予用户的许可是针对个人使用的许可。如用户有需要在个人使用的范围以外使用本公司游戏软件及本公司游戏服务或者将本公司游戏软件与本公司游戏服务用于任何商业用途，则用户应与本公司联系并获得本公司另行授权。任何未经本公司许可的安装、使用、访问、显示、运行、商业使用等行为均属对本公司的侵权。 </p>
                  <p>
                    2、除非本协议另有约定，否则，未经本公司事先书面同意，用户不得实施下列行为（无论是营利的还是非营利的）： </p>
                  <p>　(1)复制、翻录、反编译（de-compile）、反汇编（disassemble）、传播和陈列本公司游戏软件的程序、使用手册和其它图文音像资料的全部或部分内容，或对本公司游戏软件的任何功能或程序进行反向工程（reverse engineering）。 </p>
                  <p>　(2)公开展示和播放本公司游戏软件/本公司游戏服务的全部或部分内容。 </p>
                  <p>　(3)出租、销售本公司游戏软件或者利用本公司游戏软件从事任何营利行为。 </p>
                  <p>　(4)修改或遮盖本公司游戏软件程序、图像、动画、包装和手册等内容上的服务名称、公司标志、商标（或）版权信息、其他知识产权声明等内容。 </p>
                  <p>　(5)其它违反商标法、著作权法、计算机软件保护条例和相关法律法规、国际条约的行为。 </p>
                  <p class="p_title1"><strong>第二条  不正当游戏行为 </strong></p>
                  <p>1、用户应当自行承担其所发布的信息内容所涉及的责任。特别地，用户不得发布下列内容： </p>
                  <p>　(1)反对中华人民共和国宪法所确定的基本原则的； </p>
                  <p>　(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； </p>
                  <p>　(3)损害国家荣誉和利益的； </p>
                  <p>　(4)煽动民族仇恨、民族歧视，破坏民族团结的； </p>
                  <p>　(5)破坏国家宗教政策，宣扬邪教和封建迷信的； </p>
                  <p>　(6)散布谣言，扰乱社会秩序，破坏社会稳定的； </p>
                  <p>　(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； </p>
                  <p>　(8)侮辱或者诽谤他人，侵害他人合法权益的； </p>
                  <p>　(9)宣扬、教唆使用外挂、私服以及木马的相关内容的； </p>
                  <p>　(10)发布任何经本公司合理判断为不妥当或者本公司未认可的软件、文件等在内的主页地址或者链接的； </p>
                  <p>　(11)含有中华人民共和国法律、行政法规禁止的其他内容的。 </p>
                  <p>
                    2、用户不得通过不正当的手段或其他不公平的手段使用本公司游戏软件、本公司游戏服务或参与本公司游戏活动。 </p>
                  <p>用户不得干扰本公司正常地提供软件和服务，包括但不限于：
                    <p>　(1)攻击、侵入本公司的网站服务器/游戏服务器或使网站服务器/游戏服务器过载；</p>
                    <p>　(2)破解、修改本公司提供的本公司游戏软件程序；</p>
                    <p>　(3)制作、发布、传播、使用任何形式的妨碍游戏公平性的辅助工具或程序(包括但不限于“外挂”, “外挂”是指独立于游戏软件之外的，能够在游戏运行的同时影响游戏操作的所有程序，包括但不限于模拟用户操作、改变操作环境、修改数据等一切类型。）</p>
                    <p>　(4)利用程序的漏洞和错误(Bug)破坏游戏的正常进行或传播该漏洞或错误(Bug)；</p>
                    <p>　(5)不合理地干扰或阻碍他人使用本公司所提供的软件和服务；</p>
                    <p>　(6)通过异常或者非法的方式使用本公司的软件(包括但不限于利用本公司游戏软件登录游戏私服)；</p>
                    <p>　(7)使用异常的方法登录游戏（包括但不限于使用非本公司开发、授权或认可的第三方软件、系统登录游戏）、使用网络加速器等外挂软件或机器人程序等恶意破坏服务设施、扰乱正常服务秩序的行为；</p>
                    <p>　(8)修改、翻译、注释、整理、汇编、演绎或采用其他方式破译本公司游戏软件；</p>
                    <p>　(9)利用本软件或者线上游戏系统可能存在的技术缺陷或漏洞而以各种形式为自己及他人牟利（包括但不限于复制游戏虚拟物品等）或者从事其他不正当行为等。</p>
                  </p>
                  <p>用户同意以游戏程序中的监测数据作为判断用户是否有通过使用外挂程序等方法进行游戏作弊行为的依据。
                    公司根据用户异常游戏数据判断用户在游戏中存在使用外挂或其他游戏作弊行为的，公司有权在告知违规用户（包括但不限于站内通知、公告等形式）后，处罚相关用户。</p>
                  <p class="p_title1"><strong>第三条  服务的中断、中止、终止  </strong></p>
                  <p>1、对用户服务的中止与终止 </p>
                  <p>　(1)用户有发布违法信息、严重违背社会公德、以及其他违反法律禁止性规定的行为，本公司应当立即终止对用户提供服务。 </p>
                  <p>　(2)用户在接受本公司服务时实施不正当行为的，本公司有权终止对用户提供服务。该不正当行为的具体情形应当在本协议中有明确约定或属于本公司事先明确告知的应被终止服务的禁止性行为，否则，本公司不得终止对用户提供服务。 </p>
                  <p>　(3)用户实施违反本协议的行为，本公司有权中止对用户提供全部或部分服务；本公司采取中止措施应当通知用户并告知中止期间，中止期间应该是合理的，中止期间届满本公司应当及时恢复对用户的服务。 </p>
                  <p>　(4)本公司根据本款⑴、⑵、⑶项的约定中止或终止对用户提供部分或全部服务的，本公司应负举证责任。 </p>
                  <p>2、对游戏服务器运营的中断、中止与终止 </p>
                  <p>　(1)为了游戏服务器的正常运行，本公司需要定期对游戏服务器进行停机维护或针对突发事件进行紧急停机维护；因上述情况而造成的正常服务中断、中止，请您予以理解，本公司则有义务尽力将中断时间限制在最短时间内。 </p>
                  <p>　发生下列情形之一时，本公司有权终止或中断游戏服务器所提供之全部或部分服务，对因此而产生的不便或损害，本公司对用户或第三人均不承担任何责任： </p>
                  <p>　1.定期检查或施工，更新软硬件等，本公司有权暂停服务，但本公司会尽快完成维护、更新工作；</p>
                  <p>　2.游戏服务器遭受损害，无法正常运作； </p>
                  <p>　3.突发性的软硬件设备与电子通信设备故障；</p>
                  <p>　4.网络提供商线路或其他故障；</p>
                  <p>　5.在紧急情况之下依照法律的规定或为用户及第三者之人身安全；</p>
                  <p>　6.第三方原因或其他不可抗力的情形。</p>
                  <p>　(2)本公司保留在其认为有必要的情况下，终止或部分终止提供本公司游戏服务的权利，终止前将提前60天予以公告。 </p>
                  <p>
                    不管由于何种原因终止本公司游戏服务，用户应采取相应的措施自行处理游戏虚拟物品，包括但不限于注销或停止使用用户账号、游戏虚拟物品等相关事宜。用户不得因全面终止本公司游戏服务而要求本公司承担除用户已经购买但尚未使用的游戏虚拟货币外任何形式的赔偿或补偿责任，包括但不限于因不再能继续使用用户账号、游戏虚拟物品等而要求的赔偿。</p>
                  <p class="p_title1"><strong>第四条  惩罚的类型与定义 </strong></p>
                  <p>除非本协议另有其他约定，若您违反本协议的规定，可能遭受以下一项或几项惩罚: </p>
                  <p>(1)警告：警告仅仅是针对违反游戏规则的玩家做出的教育导向，它是用于正常管理游戏运行的一种方式；</p>
                  <p>(2)禁言：关闭违规玩家的部分或全部聊天频道，强制暂停玩家用户账号的线上对话功能，使玩家用户账号无法与其他玩家对话，直到此次处罚到期或是取消。禁言时长视违规严重程度依次为：2小时、1天、3天、7天、30天、365天、3650天；</p>
                  <p>(3)强制离线：强制让违规玩家离开当前游戏，结束玩家当前游戏程序的执行； </p>
                  <p>(4)封停账号：暂停或永久停止违规玩家游戏账号登录本公司游戏服务的权利。封停时长视违规严重程度由公司进行评判；</p>
                  <p>(5)删除账号：永久删除违规玩家账号及账号中的一切信息。包括但不限于：</p>
                  <p>　i.玩家在游戏世界中的人物档案、段位信息、战力信息及排行榜榜单排名；</p>
                  <p>　ii.账号下所有的虚拟货币、虚拟道具，违规玩家将自行承担一切损失，公司不承担任何责任；</p>
                  <p>(6)承担法律责任：玩家的不当行为对他人或者本公司造成损害或者与现行法律规定相违背的，违规玩家要依法承担相应的民事、行政或刑事责任； </p>
                  <p>(7)修改昵称：对于玩家游戏人物或战队等的命名，公司有权强制修改； </p>
                  <p>(8)其他处罚措施：包括但不限于没收不正当利益（如玩家通过不正当途径获得的游戏虚拟物品）、倒扣数值（包括但不限于经验值等）等。 </p>
                  <p class="p_title1"><strong>第五条  游戏内反骚扰处理规则 </strong></p>
                  <p>本公司倡议玩家对自己的言行负责，文明游戏，健康游戏，若玩家出现本条约定的以下情形，将会被按照本条的规定进行处罚。 </p>
                  <p>1、冒充系统：在游戏中，凡是通过各种方式、行为冒充系统向其他玩家散布或传播虚假信息，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号。情节严重的，本公司保留追究用户法律责任的权利。</p>
                  <p>2、非法网站：为了维护游戏世界中的洁净与和谐，凡是宣扬非法网站的，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号。  </p>
                  <p>3、言行不雅：在游戏中，凡是通过各种方式、行为散布或传播言行不雅的信息，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号。 </p>
                  <p>4、垃圾广告信息：大量传播相同的、类似短语或无实质意义内容的言行，以及任何与所在游戏无关的信息(包括但不限于刷屏等)，或者发布、传播任何贬损、诋毁、恶意攻击所在游戏或本公司的信息或者其他不实信息，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号。 </p>
                  <p>5、地区分裂：在游戏中，凡是通过各种方式或途径引起地区纷争的行为，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号。 </p>
                  <p>6、违禁药品或行为：在游戏中，凡是涉及违禁药品的行为，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号。 </p>
                  <p>7、现实生活信息和人身恐吓：凡是涉及任何与游戏世界无直接关系的暴力行为，以及泄漏其它玩家或本公司员工的任何游戏世界和现实生活信息，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号。 </p>
                  <p>8、宣传或使用木马、外挂、病毒等信息：在游戏中，凡是通过各种方式、行为散布或传播、使用木马、外挂、病毒等信息，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号；删除档案；承担法律责任。  </p>
                  <p>9、宣传代练：在游戏中，凡是通过各种方式、行为散布或传播代练的信息，根据情况将有可能受到以下处罚：警告；强制离线；禁言；封停账号；删除档案。 </p>
                  <p>10、宣传或进行线下交易：用户只可在游戏内或者通过本公司认可的交易平台（如有）进行游戏虚拟物品交易。对于用户在任何非本公司事先认可的充值平台或其他交易平台进行充值或进行其他交易的相关行为（包括但不限于用户通过第三方进行充值或游戏内虚拟物品的购买），本公司将予以严厉打击和处罚。一经查证属实，本公司有权视具体情况采取各种处理措施，包括但不限于如下一项或几项：警告、倒扣数值、暂时冻结或回收游戏虚拟物品、强制离线、封停账号、删除档案及采取其他技术措施防止用户从事该等行为；情节严重的，本公司保留追究用户法律责任的权利。 </p>
                  <p>11、宣传或发布违法信息，严重违反社会公德：在游戏中，凡是涉及违反国家法律、违背社会道德文明准则的言语或行为，包括但不限于宣传或参与赌博、宣传邪教等行为，玩家将受到以下处罚：警告；禁言；强制离线；封停账号；删除档案；承担法律责任。  </p>
                  <p>12、其他骚扰行为：除本条上述情形外，若玩家出现其他不符合法律法规、社会公德或游戏规则的骚扰情形，根据情况将可能受到以下处罚：警告；禁言；强制离线；封停账号；删除档案；承担法律责任。
                    　　   </p>
                  <p class="p_title1"><strong>第六条 命名管理规则 </strong></p>
                  <p>虽然人物昵称、玩家所建立的组织名称等可以由玩家来命名，但是按照国家法律法规，不允许出现包括但不限于涉及种族/宗教、国家政治、国家领导人、淫秽的/粗俗的、诽谤/恐吓、宗教或宗教人物、工作人员、攻击性的、污辱性的、可能引起误会的、违禁药品等内容的名称。  </p>
                  <p>如若发现前述情形，根据玩家违规行为的情况，本公司将处以不同程度的处罚：警告；强制修改昵称；强制离线；禁言；封停账号；解散组织。 </p>
                  <p class="p_title1"><strong>第七条  利用游戏Bug以及第三方软件处理规则  </strong></p>
                  <p>1、游戏BUG指游戏系统、程序、设定等方面存在的漏洞或不合理的现象。 </p>
                  <p>玩家有义务通过电子邮箱或客服电话向本公司报告在游戏中出现的BUG。严禁玩家直接或间接利用游戏BUG、程序漏洞等获利或扰乱游戏秩序，或者利用BUG、漏洞以达到个人目的。如果玩家有该等行为，一经查实，可能受到如下处罚措施：没收玩家利用BUG进行游戏而直接、间接获得之不正当利益；倒扣数值（包括但不限于经验值）；强制离线；封停账号；删除档案；承担法律责任。 </p>
                  <p>2、未经许可的第三方软件指可用于在游戏中获取优势，但不属于相应本公司游戏软件一部分的任何文件或程序，包括作弊性质的外挂以及相关辅助性质的外挂等（包括但不限于自动升级、自动练级、自动吃药、自动完成任务、加速性质、模拟加点、战斗显血或其他超出游戏设定范围的操作）。如若发现使用作弊性质的外挂以及相关辅助性质的外挂等，我们将对其采取严厉的处罚措施，包括：没收玩家利用第三方软件进行游戏而直接、间接获得之不正当利益；倒扣数值（包括但不限于经验值）；强制离线；封停账号；删除档案；承担法律责任。 </p>
                  <p>任何与本公司游戏软件同时使用的外部第三方软件可能会对游戏产生不良的影响。使用外部应用程序可能造成的主要后果是数据传送不流畅，导致玩家频繁掉线或卡机。外部应用软件可能使玩家移动智能设备（包括但不限于手机、平板电脑）感染病毒、间谍软件和其他不明程序的侵扰。第三方软件还可能窃取玩家存储在移动智能设备（包括但不限于手机、平板电脑）中的信息，例如账号、密码等个人信息。 </p>
                  <p class="p_title1"><strong>第八条  盗号与盗号相关行为处理规则 </strong></p>
                  <p>为保障玩家能愉悦的体验游戏，保障玩家的正当利益不受到侵害，本公司对盗号及盗号相关行为（包括但不限于盗取用户账号、游戏数据、玩家个人资料、协助盗号者操作及转移游戏虚拟物品等行为）将予以严厉打击和处罚。一经查证属实或应有权机关的要求，本公司有权视具体情况立即采取收回游戏虚拟物品、封停账号、删除档案等处罚措施，情节严重的，本公司保留对涉案玩家追究法律责任的权利。 </p>
                  <p>尽管您未主动参与盗号，但如您获得的游戏虚拟物品是他人通过盗号方式获得，而后转移或者分配给您的，为了维护被盗玩家的权益及游戏中公平公正的游戏秩序，本公司有权收回您购买的该游戏虚拟物品。 </p>
                  <p>在查证玩家被盗事件的过程中，为能够快速调查、处理问题，本公司可能会请相关玩家协助调查，其方式包括但不限于禁止登录调查、线上交流、线下交流等。 </p>
                  <p class="p_title1"><strong>第九条  游戏内欺诈行为处理规则 </strong></p>
                  <p>诚实信用是游戏中的基本原则。游戏中的欺诈行为是指以非法占有为目的，通过虚假承诺、虚构事实、隐瞒真相、故意误导、冒充他人、故意混淆等方式，骗取他人的游戏虚拟物品或其它财物。游戏内的欺诈行为包括昵称相似欺诈、冒充好友、冒充官方及其他形式。 </p>
                  <p>如玩家在游戏内进行欺诈，我们将根据玩家欺诈情节，采取各种处理措施，包括但不限于如下一项或几项：收回游戏虚拟物品；倒扣数值（包括但不限于经验值）；强制离线；封停账号。 </p>
                  <p>尽管您未主动参与欺诈，但如您获得的游戏虚拟物品是他人通过欺诈方式获得，而后转移或者分配给您的，为了维护被盗玩家的权益及游戏中公平公正的游戏秩序，本公司有权收回您购买的该游戏虚拟物品。 </p>
                  <p class="p_title1"><strong>第十条  与充值相关的不诚信行为处理规则</strong></p>
                  <p>诚实信用也是用户在充值相关行为中应遵循的基本原则。用户成功为本公司游戏服务充值获得虚拟物品使用权或增值服务（以下统称“充值收益”）后，如对本公司游戏服务有任何误会、不满意、建议或其他诉求的，可随时通过电子邮箱或客服电话通知本公司。用户未选择该等方式而是另行进行退款操作的，应立即通过电子邮箱或客服电话通知本公司扣除相应的充值收益；如用户进行退款操作后仍占有充值服务的属不诚信行为，本公司将予以严厉打击和处罚，包括但不限于如下一项或几项：警告、倒扣数值、暂时冻结或回收游戏虚拟物品或增值服务、强制离线、封停账号、删除档案及采取其他技术措施防止用户从事该等行为；情节严重的，本公司保留追究用户法律责任（包括但不限于民事责任、刑事责任）的权利。</p>
                  <p class="p_title1"><strong>第十一条  数据异常处理规则 </strong></p>
                  <p>如果本公司发现用户数据异常或者存在违法或者其他不正当行为（“其他不正当行为”包括但不限于利用bug或非正常游戏方式提升游戏角色数据、游戏内的角色排名等）的，本公司有权进行调查并采取相应措施，包括但不限于：(1)删除游戏虚拟物品；(2)没收不正当利益；(3)数据回退或清零；(4)禁言；(5)强制离线；(6)封停直至删除用户账号等。用户无权因此要求本公司承担任何责任。 </p>
                  <p class="p_title1"><strong>第十二条  牟取不正当利益行为处理规则 </strong></p>
                  <p>用户理解并同意，本公司向用户提供本公司游戏服务，是为了让用户体验丰富多彩的游戏内容，而非利用本公司游戏服务赚取现实世界的金钱或牟取经济利益。而用户不以体验游戏内容为目的，在游戏中主要进行交易和牟利的行为，会破坏游戏内经济系统的公平性或平衡性，从而对其他用户的游戏体验、游戏本身造成不利影响。 </p>
                  <p>因此，用户承诺不以营利为目的从事游戏行为或交易虚拟物品，任何以营利为目的从事游戏行为或交易虚拟物品的情形将被视为牟取不正当利益，包括但不限于用户：</p>
                  <p>(1)注册多个用户账号和/或游戏角色ID，以营利为目的进行游戏行为；</p>
                  <p>(2)从事游戏内单一或系列产出玩法，将获得的虚拟物品出售获利；</p>
                  <p>(3)利用不同服务器的虚拟物品价值差异，在不同服务器买卖虚拟物品获利；</p>
                  <p>(4)充当游戏账号、虚拟物品交易中介收取费用获利；</p>
                  <p>(5)在非本公司提供或认可的交易平台上交易用户账号或虚拟物品获利；</p>
                  <p>(6)将游戏内获得的虚拟物品用于出售获利而不注重本身角色实力的提升，角色多个技能、修炼、装备、召唤水平与角色等级相差较大的；</p>
                  <p>(7)利用游戏行为和游戏内容组织或参与赌博、实施或参与实施盗窃他人财产或虚拟物品等涉嫌违法犯罪行为的；</p>
                  <p>(8)其他任何不以正常的游戏娱乐互动需要为目的的游戏内牟利行为。</p>
                  <p>本公司有权对牟取不正当利益的用户同时采取以下措施：对所有参与牟取不正当利益的用户账号（包括但不限于获得、转移、出售游戏虚拟物品的账号）进行倒扣数值、回收游戏虚拟物品、强制离线、封停账号、删除档案及采取其他技术措施防止用户从事该等行为；情节严重的，本公司保留追究用户法律责任的权利。</p>
                  <p class="p_title1"><strong>第十三条  游戏画面使用规则 </strong></p>
                  <p>未经本公司事先书面允许，您不得通过第三方软件公开全部或部分展示、复制、传播、播放本公司游戏服务中的游戏画面，例如进行游戏直播、录播、在第三方软件平台上传包含游戏画面的视频或图像、将本公司游戏服务中的游戏画面作商业使用等。否则本公司将有权根据您的违约情节，采取各种处理措施，包括但不限于如下一项或几项，且本公司保留进一步追究您法律责任的权利：强制离线、封停账号。 </p>
                  <p class="p_title1"><strong>第十四条  损害赔偿 </strong></p>
                  <p>如您在游戏内的违规行为给本公司或第三方造成损害的，您应承担相应的法律责任，而且针对自身的损害本公司有权采取一切合理的行动，包括但不限于收回游戏虚拟物品等，本公司并有权向您索回损失额并要求您承担本公司合理的追索费用。 </p>
                  <p class="p_title1">⊙重要提示 </p>
                  <p>青少年用户必须遵守全国青少年网络文明公约：要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。 </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Floor />
  </div>
</template>
<script>
import Head from '@/views/Head'
import Floor from '@/views/Floor'
import TitleBg from '@/views/NewsDetails/TitleBg'

export default ({
  name: "GameSecurity",
  components: {
    Head,
    Floor,
    TitleBg
  },
  data() {
    return {
      showTitle: false
    }
  },
  methods: {
    handleClick(path) {
      this.$router.push({
        path
      })
    }
  }
})
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  vertical-align: middle;
}

p {
  margin-bottom: 0;
}

html,
body {
  background-color: #f6f7fb;
  font-family: "Microsoft Yahei" !important;
}

body {
  display: none;
}

/* ::-webkit-scrollbar {
  display: none;
  } */
body {
  background-color: #fff;
  /* font-size: 1*2rem; */
}

.help {
  color: #e62631 !important;
}

.big_logo .bgi {
  width: 100%;
}

.doing-what {
  border-left: 0.125*2rem solid #3a79d4;
  font-size: 0.6875*2rem;
  padding-left: 0.6125*2rem;
  line-height: 0.6875*2rem;
  margin: 0.625*2rem 10.3125*2rem;
}

.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-content .help-box {
  margin: 1.25*2rem 0;
  overflow: hidden;
  width: 35.625*2rem;
  background-color: #fff;
  padding: 0.8125*2rem;
  box-sizing: border-box;
  border-radius: 0.1875*2rem;
  border: 0.0625*2rem solid #eee;
}

.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875*2rem;
  font-size: 0.5625*2rem;
  margin-bottom: 0.625*2rem;
}

.help-content .help-box li .top {
  width: 33.6875*2rem;
  height: 2.0625*2rem;
  padding: 0 1.25*2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .jiantou {
  width: 0.3125*2rem;
  height: 0.3125*2rem;
  border-left: 0.0625*2rem solid #ccc;
  border-bottom: 0.0625*2rem solid #ccc;
  transform: rotate(-45deg);
}

.help-content .help-box li:last-of-type .jiantou {
  transform: rotate(-45deg);
}

.help-content .help-box li .problem {
  padding: 0 1.25*2rem 0.625*2rem 1.25*2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .topic {
  color: red;
  margin-top: 0.625*2rem;
}

.help-content .help-box li .problem .content {
  padding: 0.625*2rem;
  margin-top: 0.625*2rem;
  background-color: #f5f5f5;
  border-radius: 0.1875*2rem;
  border: 0.0325*2rem solid #e7e7e7;
  font-size: 0.4375*2rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .problem .content span:not(:last-of-type) {
  height: 1.25*2rem;
}

.help-content .help-box li .bottom {
  border-top: 0.0325*2rem solid #eee;
  display: block;
}

.problem p {
  cursor: pointer;
}

.problem .bottomic {
  display: none;
}

.p_con,
.p_join {
  line-height: 1.25*2rem;
  font-size: 0.5*2rem;
  text-align: center;
}

.p_content {

  p {
    text-indent: 2em;
    line-height: 1.2*2rem;
    font-size: 0.4375*2rem;
  }

  .p_title {
    font-size: 0.5587*2rem;
    font-weight: 700;
    line-height: 1.5*2rem;
    text-align: center;
  }

  .p_title1 {
    font-size: 0.5587*2rem;
    font-weight: 700;
    line-height: 1.5*2rem;
    text-indent: 0;
  }

  .p_strong {
    font-weight: bold;
  }

  .p_link {
    color: #3a79d4;
    cursor: pointer;
  }

  .p_line {
    font-size: 1*2rem;
    width: 0.625*2rem;
    color: #c4222e;
  }
}

//.p_content .p_title {
//  font-size: 0.5587*2rem;
//  font-weight: 700;
//  line-height: 1.5*2rem;
//}
//
//.p_content .p_line {
//  font-size: 1*2rem;
//  width: 0.625*2rem;
//  color: #c4222e;
//}
//
//.p_content .p_icon {
//  text-indent: 2em;
//  line-height: 1.2*2rem;
//  font-size: 0.4375*2rem;
//}
//
//.p_content .min_title {
//  font-size: 0.4688*2rem;
//  font-weight: 700;
//}
//
//.left {
//  float: left;
//}
//
//.media {
//  margin-left: 3.125*2rem;
//  margin-top: 1.25*2rem;
//}
//
//.p_content img {
//  width: 100%;
//}
</style>

